#header {
  padding: 0em 0 0 0;
}

#header h1 {
  padding: 1em 0 1em 0;
}

@media screen and (max-width: 840px) {
  /* Navigation menu line and arrow */
  #titleBar {
    box-shadow: 0 4px 0 0 #9ac2b9;
  }
}

/* Button to go to top */
#topBtn {
  display: none; /* Hidden by default */
  position: fixed; /* Fixed/sticky position */
  bottom: 20px; /* Place the button at the bottom of the page */
  right: 30px; /* Place the button 30px from the right */
  z-index: 99; /* Make sure it does not overlap */
  border: none; /* Remove borders */
  outline: none; /* Remove outline */
  background-color: #9ac2b9;
  color: white; /* Text color */
  cursor: pointer; /* Add a mouse pointer on hover */
  padding: 10px; /* Some padding */
  border-radius: 5px; /* Rounded corners */
  font-size: 36px; /* Increase font size */
  line-height: 36px;
  min-width: 3em;
  text-align: center;
}

#topBtn:hover {
  background-color: rgb(157, 201, 192);
}

/* Navigation menu line and arrow */
#nav::after {
  background-color: #9ac2b9;
}

#nav > ul > li.current::before {
  background-color: #9ac2b9;
}


/* Links */
a {
  color: #9ac2b9;
}

a:hover {
  color: rgb(157, 201, 192);
  font-weight: bold;
}

/* CTA block*/
.wrapper.style3 {
  background-color: #9ac2b9;
}

/* Icons */
.icon.major {
  background-color: #9ac2b9;
}
